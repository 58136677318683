<template>
  <div class="select">
    <v-button class="select__button" primary :to="{ name: 'login-owner' }">Собственник</v-button>
    <v-button :to="{ name: 'login-staff' }">Агент</v-button>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'Login',
  components: { VButton }
}
</script>
